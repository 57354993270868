import React, { useEffect, useState } from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { Auth, Hub } from "aws-amplify";

import { Alert } from "react-bootstrap";
import Header from "./elements/header";
import MainTemplate from "./templates/main";
import SignInForm from "./components/auth/signinform";
import EmployeeDashboardTemplate from "./templates/employeedashboard";
import CustomerDashboardTemplate from "./templates/employeedashboard";
import Notfound from "./templates/notfound";

import "./App.css";

function AppTemplate() {
	const [user, setUser] = useState(null);
	const [userscope, setUserscope] = useState(null);

	useEffect(() => {
		getUser().then(userData => {
			if (userData) {
				setUser(userData);
				let groups =
					userData.signInUserSession.accessToken.payload["cognito:groups"];
				if (groups.includes("Admins")) {
					setUserscope("admin");
				} else if (groups.includes("us-east-1_lsuYXB51V_UnitedLabs")) {
					setUserscope("training");
				} else {
					setUserscope("customer");
				}
			}
		});
		Hub.listen("auth", ({ payload: { event, data } }) => {
			switch (event) {
			case "signIn":
				getUser().then(userData => {
					setUser(userData);
					let groups =
							userData.signInUserSession.accessToken.payload["cognito:groups"];
					if (groups.includes("Admins")) {
						setUserscope("admin");
					} else if (groups.includes("us-east-1_lsuYXB51V_UnitedLabs")) {
						setUserscope("training");
					} else {
						setUserscope("customer");
					}
				});
				break;
			case "cognitoHostedUI":
				getUser().then(userData => setUser(userData));
				break;
			case "signOut":
				setUser(null);
				break;
			case "signIn_failure":
			case "cognitoHostedUI_failure":
				console.log("Sign in failure", data);
				break;
			}
		});
	}, []);

	function getUser() {
		return Auth.currentAuthenticatedUser().catch(() => {
			console.log("Not signed in");
			//Auth.federatedSignIn();
		});
	}

	const useremail = user ? user.attributes.email : " ";
	return (
		<div>
			<Alert variant="warning">
				<Alert.Heading>
					You are viewing the development version of this site.
				</Alert.Heading>
				<p>
					This version may use separate databases and assets from the production
					version. Some development and debug messages will appear here.
				</p>
				<hr />
				<p className="mb-0">
					User: {user ? JSON.stringify(user.attributes) : "None"}
					<br />
					Groups:
					{user
						? JSON.stringify(
							user.signInUserSession.accessToken.payload["cognito:groups"]
							// eslint-disable-next-line
						  )
						: "None"}
					;
				</p>
			</Alert>

			{user ? (
				<>
					<Router>
						<Switch>
							<Route
								exact
								strict
								path="/"
								render={() =>
									userscope === "employee" || userscope === "admin" ? (
										<EmployeeDashboardTemplate
											useremail={user.attributes.email}
											userscope={userscope}
										/>
									) : (
										<CustomerDashboardTemplate
											useremail={user.attributes.email}
											userscope={userscope}
										/>
									)
								}
							/>
							<Route
								path={["/:section/:view", "/:section"]}
								render={() => (
									<MainTemplate useremail={useremail} userscope={userscope} />
								)}
							/>
							<Route component={Notfound} />
						</Switch>
					</Router>
				</>
			) : (
				<>
					<div>
						<Header view="customerdashboard" />

						{!user && <SignInForm />}
					</div>
				</>
			)}
		</div>
	);
}

export default AppTemplate;
