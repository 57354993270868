import React, { useState, useMemo, useEffect } from "react";

import { API, graphqlOperation } from "aws-amplify";

import Table from "../../components/videostable";

import matchSorter from "match-sorter";
import "../../components/vidstable.css";

const listVideosQuery = `query ListVideos(
    $filter: ModelVideoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVideos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        iscustomer
        istraining
        url
        products {
          id
          name
          url
          description
        }
        taggedKeywords {
          id
          name
        }
        keywordstring
        categories {
          id
          name
        }
        inCategory {
          id
          name
        }
        inPost {
          id
          type
          title
          subtitle
          shortDesc
          longDesc
          keywordstring
          startPublishing
          endPublishing
        }
      }
      nextToken
    }
  }
  `;

function VideosTemplate({ searchQuery }) {
	console.log(searchQuery);
	const [videos, setVideos] = useState(null);
	const [videosLoaded, setVideosLoaded] = useState(null);
	const fetchVideos = async () => {
		let filter = {
			iscustomer: {
				eq: true
			}
		};
		const allVids = await API.graphql(
			graphqlOperation(listVideosQuery, { limit: 500, filter: filter })
		);
		console.log(allVids.data.listVideos.items);
		setVideos(allVids.data.listVideos.items);
		setVideosLoaded(true);
	};
	const tabledata = useMemo(() => videos);
	const tablecolumns = useMemo(
		() => [
			{
				Header: "Title",
				accessor: "title", // String-based value accessors!,
				FilterMethod: (filter, rows) =>
					matchSorter(rows, filter.value, { keys: ["title", "id"] }),
				filterAll: true
			},
			{
				Header: "Products Featured",
				accessor: function accessor(d) {
					if (d.products === undefined) {
						return " ";
					} else {
						return d.products.map(function(product) {
							return product.id + " ";
						});
					}
				}
			},
			{
				id: "inCategory",
				Header: "Category",
				accessor: "inCategory.name",
				filterAll: true,
				headerStyle: { display: "none" },
				style: { display: "none" }
			},
			{
				Header: "id",
				accessor: "id",
				filterAll: true,
				headerStyle: { display: "none" },
				style: { display: "none" }
			},
			{
				id: "keywordstring",
				Header: "Keywordstring",
				accessor: "keywordstring",
				filterAll: true,
				headerStyle: { display: "none" },
				style: { display: "none" }
			}
		],
		[]
	);
	useEffect(() => {
		(async () => {
			setVideosLoaded(false);
			await fetchVideos();
		})();
	}, []);
	return (
		<div className="table">
			{videosLoaded && (
				<Table
					columns={tablecolumns}
					data={tabledata}
					searchQuery={searchQuery}
				/>
			)}
		</div>
	);
}

export default VideosTemplate;
