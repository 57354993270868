import React from "react";
import {
	Container,
	Row,
	Col,
	Image,
	Card,
	Button,
	Form
} from "react-bootstrap";
import { withRouter } from "react-router-dom";
import Header from "../elements/header";
import CategorySelectElement from "../elements/categoryselect";
import SearchBox from "../elements/searchbox";
import "./dashboard.css";
import videoicon from "../video_1541069.png";

class EmployeeDashboardTemplate extends React.Component {
	render() {
		const ViewCustomerVidsButton = withRouter(({ history }) => (
			<Button
				id="view-customer-vids-button"
				className="viewall-button"
				variant="light"
				onClick={() => {
					history.push("/c/videos");
				}}
			>
				View All Customer Facing Videos
			</Button>
		));
		const ViewTrainingVidsButton = withRouter(({ history }) => (
			<Button
				id="view-training-vids-button"
				className="viewall-button"
				variant="light"
				onClick={() => {
					console.log("view-training-vids-button clicked");
					history.push("/training/videos");
				}}
			>
				View All Employee Facing Videos
			</Button>
		));
		const ViewFieldPostsButton = withRouter(({ history }) => (
			<Button
				id="view-training-posts-button"
				className="viewall-button"
				variant="light"
				onClick={() => {
					history.push("/training/fromthefield");
				}}
			>
				View All QUICK NOTES From The Field
			</Button>
		));

		return (
			<div id="e-dash-wrapper">
				<Header
					userscope={this.props.userscope}
					useremail={this.props.useremail}
					view="employeedashboard"
				/>
				<Container fluid id="e-dash-content">
					<Row>
						<Col>
							<h1 id="employee-welcome-text">
								Welcome to our multimedia and information resource library for
								United Sales Representatives
							</h1>
							<h2 id="employee-subhead-text">
								Get started by selecting a portal:
							</h2>
						</Col>
					</Row>
					<Row>
						<Col sm={false} lg={1}></Col>
						<Col>
							<Card id="customer-vids-card" style={{ width: "23rem" }}>
								<Card.Body>
									<Card.Text>
										<Image src={videoicon} />
										Share some of our amazing product videos, demonstrations,
										and cost savings programs:
									</Card.Text>
									<Row>
										<Col>
											<Form.Group>
												<ViewCustomerVidsButton />
											</Form.Group>
										</Col>
									</Row>
									<Row>
										<Col>
											<SearchBox section="c" view="videos" />
										</Col>
									</Row>
									<Row>
										<Col>
											<Form.Group>
												<CategorySelectElement section="c" view="videos" />
											</Form.Group>
										</Col>
									</Row>
								</Card.Body>
							</Card>
						</Col>
						<Col>
							<Card id="employee-vids-card" style={{ width: "23rem" }}>
								<Card.Body>
									<Card.Text>
										<Image src={videoicon} />
										Search educational and training videos for United Sales
										Representatives only:
										<br />
										<br />
									</Card.Text>
									<Row>
										<Col>
											<Form.Group>
												<ViewTrainingVidsButton />
											</Form.Group>
										</Col>
									</Row>
									<Row>
										<Col>
											<SearchBox section="training" view="videos" />
										</Col>
									</Row>
									<Row>
										<Col>
											<Form.Group>
												<CategorySelectElement
													section="training"
													view="videos"
												/>
											</Form.Group>
										</Col>
									</Row>
								</Card.Body>
							</Card>
						</Col>
						<Col>
							<Card id="employee-posts-card" style={{ width: "23rem" }}>
								<Card.Body id="employee-posts-card-body">
									<Card.Text id="employee-posts-card-text">
										<Image
											id="people-cardimage"
											src={process.env.PUBLIC_URL + "/img/QUICKnotes.png"} // eslint-disable-line no-undef
										/>
										<br />
										See actual before and after examples of product applications{" "}
										<b>From the Field</b>:
									</Card.Text>
									<Row>
										<Col>
											<Form.Group>
												<ViewFieldPostsButton />
											</Form.Group>
										</Col>
									</Row>
									<Row>
										<Col>
											<SearchBox section="training" view="fromthefield" />
										</Col>
									</Row>
									<Row>
										<Col>
											<Form.Group>
												<CategorySelectElement
													section="training"
													view="fromthefield"
												/>
											</Form.Group>
										</Col>
									</Row>
								</Card.Body>
							</Card>
						</Col>
						<Col sm={false} lg={1}></Col>
					</Row>
				</Container>
			</div>
		);
	}
}
export default withRouter(EmployeeDashboardTemplate);
