import "core-js/stable";
import "regenerator-runtime/runtime";
import Amplify from "aws-amplify";
import "whatwg-fetch";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import AppTemplate from "./App";
import Footer from "./elements/footer";
import { Container } from "react-bootstrap";

import "./index.css";
import * as serviceWorker from "./serviceWorker";
// eslint-disable-next-line no-undef
const isProduction = process.env.NODE_ENV === "production" ? true : false;

// Assuming you have two redirect URIs, and the first is for localhost and second is for production

const RedirectSignIn = isProduction
	? "https://dev.ulabs.info/"
	: "http://localhost:3000/";
const RedirectSignOut = isProduction
	? "https://dev.ulabs.info/"
	: "http://localhost:3000/";
const userPoolWebClientId = isProduction
	? "22g6ep7mp6ck3pf78lp37g9n8"
	: "22g6ep7mp6ck3pf78lp37g9n8";

// *** You must manually merge changes from aws-exports.js into AwsConfig ***
const AwsConfig = {
	aws_project_region: "us-east-1",
	aws_cognito_identity_pool_id:
		"us-east-1:b30cde9c-6fbe-4b3c-9842-7d9235c60c93",
	aws_cognito_region: "us-east-1",
	aws_user_pools_id: "us-east-1_lsuYXB51V",
	aws_user_pools_web_client_id: userPoolWebClientId,
	oauth: {
		//domain: "devauth.ulabs.info", // do not include https://
		redirectSignIn: RedirectSignIn,
		redirectSignOut: RedirectSignOut,
		aws_user_pools_web_client_id: userPoolWebClientId,
		domain: "devauth.ulabs.info",
		scope: [
			"phone",
			"email",
			"openid",
			"profile",
			"aws.cognito.signin.user.admin"
		],
		responseType: "code"
	},

	federationTarget: "COGNITO_USER_AND_IDENTITY_POOLS",
	aws_appsync_graphqlEndpoint:
		"https://w5nj3kvakjachgp4mupp7yupee.appsync-api.us-east-1.amazonaws.com/graphql",
	aws_appsync_region: "us-east-1",
	aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
	aws_cloud_logic_custom: [
		{
			name: "AdminQueries",
			endpoint: "https://s0993ku6ga.execute-api.us-east-1.amazonaws.com/dev",
			region: "us-east-1"
		}
	],
	aws_user_files_s3_bucket: "ulabs2651693f574564cb1ac8d151181495ed5-dev",
	aws_user_files_s3_bucket_region: "us-east-1",
	aws_mobile_analytics_app_id: "5bbd096deca2470f8a5d0408eef25e37",
	aws_mobile_analytics_app_region: "us-east-1"
};

Amplify.configure(AwsConfig);

const App = (
	<Router>
		<Container fluid id="appcontainer">
			<link
				rel="stylesheet"
				href="https://maxcdn.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css"
				integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T"
				crossOrigin="anonymous"
			/>
			<AppTemplate />
			<Footer />
		</Container>
	</Router>
);

ReactDOM.render(App, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
