import React, { useState } from "react";

import { Auth } from "aws-amplify";
import { Modal, Row, Col, Card, Button, Form, Collapse } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { faTimesCircle } from "@fortawesome/free-regular-svg-icons";

import "./auth.css";
function SignInForm() {
	const [authState, setAuthState] = useState("signIn");
	const [formValues, setFormValues] = useState({
		email: "",
		password: "",
		failedPassCount: 0
	});
	const [formState, setFormState] = useState({
		error: "",
		loadingSignIn: false,
		errorModalVisible: false,
		forgotpassVisible: false,
		resendSignUpVisible: false
	});
	const updateByPropertyName = (propertyName, value) => () => ({
		[propertyName]: value
	});
	let errHide = () => setFormState({ ...formState, errorModalVisible: false });

	const isInvalid = formValues.password === "" || formValues.email === "";

	function gotoSignUp() {
		// to switch the authState to 'signUp'
		setAuthState("signUp");
	}

	function onSubmit(event) {
		let { email, password, failedPassCount } = formValues;
		setFormState({ ...formState, loadingSignIn: true });
		Auth.signIn(email.toLowerCase(), password)
			.then(user => {
				if (
					user.challengeName === "SMS_MFA" ||
					user.challengeName === "SOFTWARE_TOKEN_MFA"
				) {
					setFormState({ ...formState, loadingSignIn: false });
				} else if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
					setFormState({ ...formState, loadingSignIn: false });
				} else if (user.challengeName === "MFA_SETUP") {
					setFormState({ ...formState, loadingSignIn: false });
				} else {
					setFormState({ ...formState, loadingSignIn: false });
					setAuthState("signedIn");
				}
			})
			.catch(err => {
				if (err.code === "UserNotConfirmedException") {
					// The error happens if the user didn't finish the confirmation step when signing up
					// In this case you need to resend the code and confirm the user
					// About how to resend the code and confirm the user, please check the signUp part
					setFormState({ ...formState, loadingSignIn: false });
					this.setState({
						error: {
							message:
								"Your email has not yet been confirmed, you must confirm email before you can sign in."
						}
					});
					setFormState({ ...formState, resendSignUpVisible: false });
				} else if (err.code === "PasswordResetRequiredException") {
					setFormState({ ...formState, loadingSignIn: false });
					// The error happens when the password is reset in the Cognito console
					// In this case you need to call forgotPassword to reset the password
					// Please check the Forgot Password part.
				} else if (err.code === "NotAuthorizedException") {
					failedPassCount = failedPassCount + 1;
					setFormState({ ...formState, loadingSignIn: false });
					setFormState({
						...formState,
						error: { message: "Incorrect Password. Try Again?" }
					});
					setFormValues({ ...formValues, failedPassCount: failedPassCount });
					if (failedPassCount > 1) {
						setFormState({ ...formState, forgotpassVisible: false });
					}
					// The error happens when the incorrect password is provided
				} else if (err.code === "UserNotFoundException") {
					// This error happens when the supplied username/email does not exist in the Cognito user pool
					setFormState({ ...formState, loadingSignIn: false });
					this.setState({
						error: {
							message: "User Does Not Exist, Please Create an Account"
						}
					});
				} else {
					setFormState({ ...formState, loadingSignIn: false });
					console.log(err);
					this.setState(updateByPropertyName("error", err));
				}
				setFormState({ ...formState, loadingSignIn: false });
				setFormState({ ...formState, errorModalVisible: true });
			});

		event.preventDefault();
	}
	return (
		<Row className="justify-content-md-center auth-main-row">
			<Col md="auto">
				<Card className="auth-main-card">
					<Card.Body>
						{authState === "signedUp" && (
							<Card.Title>
								<h1>Success!</h1>
								<h2>Your Email Has Been Confirmed</h2>
								<h3>
									You may now sign in to see what&apos;s new at United
									Laboratories!
								</h3>
							</Card.Title>
						)}
						{authState === "signIn" && (
							<Card.Title>
								<h1 style={{ fontSize: "22pt" }}>Welcome to uLabs.info!</h1>
								<h2 style={{ fontSize: "18pt" }}>
									United Laboratories video and product information portal
								</h2>
								<h3>
									We&apos;d love to share what&apos;s new at United
									Laboratories!
								</h3>
							</Card.Title>
						)}
						{authState === "signedOut" && (
							<Card.Title>
								You Have Signed Out
								<br />
								See You Later!
							</Card.Title>
						)}

						<hr />
						<Button
							variant="light"
							onClick={() =>
								Auth.federatedSignIn({ customProvider: "UnitedLabs" })
							}
						>
							{" "}
							United Employees Click Here to Sign In{" "}
						</Button>
						<hr />
						<Form onSubmit={onSubmit}>
							<Form.Group controlId="formBasicEmail">
								<Form.Label>Sign In With Your Email Address</Form.Label>
								<Form.Row>
									<Col>
										<Form.Control
											type="email"
											placeholder="Enter email"
											autoComplete="email"
											value={formValues.email}
											onChange={event =>
												setFormValues({
													...formValues,
													email: event.target.value.toLowerCase()
												})
											}
										/>
									</Col>
								</Form.Row>
							</Form.Group>
							<Form.Group controlId="formBasicPassword">
								<Form.Label>Enter Your Password</Form.Label>
								<Form.Row>
									<Col sm={9}>
										<Form.Control
											type="password"
											placeholder="Password"
											autoComplete="current-password"
											value={formValues.password}
											onChange={event =>
												setFormValues({
													...formValues,
													password: event.target.value
												})
											}
										/>
									</Col>
									<Col sm={3}>
										<Button variant="light" disabled={isInvalid} type="submit">
											Sign In
											{formState.loadingSignIn === true && (
												<span
													className="spinner-border spinner-border-sm"
													role="status"
													aria-hidden="true"
												></span>
											)}
										</Button>
									</Col>
								</Form.Row>
							</Form.Group>
							<Form.Text className="inputtip">
								&nbsp; Forgot Password? &nbsp;
								<span
									className="link"
									onClick={() => this.gotoForgotPassword()}
								>
									Click Here To Reset
								</span>
							</Form.Text>
						</Form>
						<Card.Text>
							<br />
							<b> Don&apos;t Have an Account? </b>
							<Button variant="link" onClick={() => gotoSignUp()}>
								Create Account
							</Button>
							<br />
						</Card.Text>
						<Card.Text>
							<b>United Employees:</b> When creating your account, be sure to
							use your @unitedlabsinc.com email to take advantage of all site
							features.
						</Card.Text>
					</Card.Body>
				</Card>
			</Col>

			<Modal
				size="lg"
				show={formState.errorModalVisible}
				onHide={errHide}
				aria-labelledby="error-modal"
				className="error-modal"
			>
				<Modal.Body>
					{formValues.error && (
						<div className="error-message">
							<br />
							<FontAwesomeIcon icon={faExclamationCircle} />
							&nbsp; {formValues.error.message}
							<Collapse in={formState.forgotpassVisible}>
								<p>
									<br />
									<b> Forgot Password? </b>

									<Button
										variant="link"
										onClick={() => this.gotoForgotPassword()}
									>
										Click Here To Reset
									</Button>
									<br />
								</p>
							</Collapse>
							<Collapse in={formState.resendSignUpVisible}>
								<p>
									<Button
										variant="link"
										onClick={() => this.resendSignUp(formValues.email)}
									>
										Click here to resend confirmation code
									</Button>
									<br />
								</p>
							</Collapse>
						</div>
					)}
					<Button
						variant="link"
						className="modal-close-button"
						onClick={errHide}
					>
						<FontAwesomeIcon icon={faTimesCircle} />
					</Button>
				</Modal.Body>
			</Modal>
		</Row>
	);
}
export default SignInForm;
