import React from "react";
import { Auth } from "aws-amplify";

function UserPanelComponent(props) {
	function signOut() {
		Auth.signOut();
	}
	return (
		<div>
			{props.useremail && (
				<p id="userpanel_greeting">
					Hello {props.useremail}
					<br />
					<button id="userpanel_signout" onClick={signOut}>
						{" "}
						Sign Out
					</button>
				</p>
			)}
		</div>
	);
}

export default UserPanelComponent;
