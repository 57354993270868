import React from "react";
import { useTable, useSortBy, useGlobalFilter } from "react-table";
// sample data array looks like this

export default function Table({ columns, data }) {
	// Use the useTable Hook to send the columns and data to build the table
	const {
		getTableProps, // table props from react-table
		getTableBodyProps, // table body props from react-table
		headerGroups, // headerGroups, if your table has groupings
		rows, // rows for the table based on the data passed
		prepareRow // Prepare the row (this function needs to be called for each row before getting the row props)
	} = useTable(
		{
			columns,
			data
		},
		useGlobalFilter,
		useSortBy
	);
	/*
    Render the UI for your table
    - react-table doesn't have UI, it's headless. We just need to put the react-table props from the Hooks, and it will do its magic automatically
  */
	return (
		<table {...getTableProps()}>
			<thead>
				{headerGroups.map((headerGroup, index) => (
					<tr key={index} {...headerGroup.getHeaderGroupProps()}>
						{headerGroup.headers.map((column, index) => (
							<th
								key={index}
								{...column.getHeaderProps(column.getSortByToggleProps())}
							>
								{column.render("Header")}
							</th>
						))}
					</tr>
				))}
			</thead>
			<tbody {...getTableBodyProps()}>
				{rows.map((row, i) => {
					prepareRow(row);
					return (
						<tr key={i} {...row.getRowProps()}>
							{row.cells.map(cell => {
								return (
									<td key={i} {...cell.getCellProps()}>
										{cell.render("Cell")}
									</td>
								);
							})}
						</tr>
					);
				})}
			</tbody>
		</table>
	);
}
