import React from "react";
import { API, graphqlOperation, Storage, Analytics } from "aws-amplify";
import { S3Image } from "aws-amplify-react";
import { Container, Row, Col, Carousel } from "react-bootstrap";
import PlayerComponent from "../../components/player";
import "./post.css";

class PostTemplate extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			products: null,
			images: {
				items: []
			},
			contributor: {
				name: " "
			},
			videos: {
				items: []
			}
		};
	}

	componentDidMount = async () => {
		await this.getPost();
		Analytics.record({
			name: "postView",
			// Attribute values must be strings
			attributes: { title: this.state.title }
		});
	};
	getPost = async () => {
		const getPostQuery = `query GetPost($id: ID!) {
          getPost(id: $id) {
            id
            type
            title
            subtitle
            shortDesc
            longDesc
            productLinks {
              items {
                product{
                id
                name
                url
                description
                }
              }
              nextToken
            }
            images {
              items {
                id
                title
                caption
                sources {
                  items{
                    id
                    url
                    s3object {
                      bucket
                      region
                      key
                    }
                  }
                }
              }
              nextToken
            }
            videos {
              items {
                id
                title
                iscustomer
                istraining
                keywordstring
                sources {
                  items{
                    id
                    type
                    s3object {
                      bucket
                      region
                      key
                    }
                  }
                }
              }
            }
            contributor {
              id
              name
              level
              posts {
                nextToken
              }
            }
            keywordstring
            catLinks {
              items {
                category{
                  id
                  name
                }
              }
              nextToken
            }
            startPublishing
            endPublishing
          }
        }`;
		try {
			const postrequest = {
				id: this.props.match.params.id
			};
			const response = await API.graphql(
				graphqlOperation(getPostQuery, postrequest)
			);

			if (response.data.getPost.videos.items.length > 0) {
				let video = response.data.getPost.videos.items[0].sources.items[0];
				video = {
					src: await Storage.get(video.s3object.key, { level: "public" }),
					type: video.type
				};

				this.setState({ video: video });
			}
			this.setState(response.data.getPost);
			this.setState({ loading: false });
		} catch (err) {
			console.log("error fetching post");
		}
	};

	render() {
		const products = this.state.productLinks
			? this.state.productLinks.items
			: [];
		return (
			<section>
				<Container hidden={this.state.loading}>
					<br />
					<h3>
						{products[0] &&
							products.map(productlink => (
								<span key={productlink.product.id}>
									United {productlink.product.id} {productlink.product.name}{" "}
									&nbsp;
								</span>
							))}
					</h3>
					<h4>{this.state.title}</h4>
					<h5>{this.state.subtitle}</h5>
					<p>
						Submitted by: &nbsp;
						<img
							id="user_badge_img"
							src={
								process.env.PUBLIC_URL +
								"/img/badge_" +
								this.state.contributor.level +
								".svg"
							}
							alt="Contributor Badge"
						/>
						{this.state.contributor.name}
					</p>
					<Row>
						<Col id="postcontent_col" sm={12} lg={8}>
							<Row>
								{this.state.videos.items[0] === undefined ? (
									<br />
								) : (
									<Col id="video_player_col" className="col-12 col-md-8">
										<PlayerComponent source={this.state.video} playing />
									</Col>
								)}
							</Row>
							<Row>
								<Col>
									{this.state.images.items[0] === undefined ? (
										<p></p>
									) : (
										<Carousel id="post_carousel">
											{this.state.images.items.map(image => {
												if (image.sources.items[0].s3object) {
													return (
														<Carousel.Item
															key={image.id}
															style={{ paddingTop: "10px" }}
														>
															<S3Image
																path={image.sources.items[0].s3object.key}
															/>
															<Carousel.Caption>
																<h3>{image.title}</h3>
																<p>{image.caption}</p>
															</Carousel.Caption>
														</Carousel.Item>
													);
												} else {
													return (
														<Carousel.Item
															key={image.id}
															style={{ paddingTop: "10px" }}
														>
															<img
																src={image.sources.items[0].url}
																alt={image.title}
															/>
															<Carousel.Caption>
																<h3>{image.title}</h3>
																<p>{image.caption}</p>
															</Carousel.Caption>
														</Carousel.Item>
													);
												}
											})}
										</Carousel>
									)}
								</Col>
							</Row>
						</Col>

						<Col id="vid_info_col" lg={4}>
							<Row>
								<Col>
									<h3>Description:</h3>
									<p
										id="longdesc_p"
										dangerouslySetInnerHTML={{ __html: this.state.longDesc }}
									/>
								</Col>
							</Row>
							<Row>
								<Col>
									{products.length < 1 ? (
										<br />
									) : (
										<div>
											<h3>Products Mentioned:</h3>
											<ul>
												{products[0] &&
													products.map(productlink => (
														<li key={productlink.product.id}>
															<a
																href={productlink.product.url}
																target="_blank"
																rel="noopener noreferrer"
															>
																United {productlink.product.id}{" "}
																{productlink.product.name}{" "}
																{productlink.product.description}
															</a>
															<br />
															<br />
														</li>
													))}
											</ul>
										</div>
									)}
								</Col>
							</Row>
						</Col>
					</Row>
				</Container>
			</section>
		);
	}
}
export default PostTemplate;
