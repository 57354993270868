import React from "react";
import { API, graphqlOperation, Storage, Auth } from "aws-amplify";
import {
	listContributors as listContributorsQuery,
	listCategorys as listCategoriesQuery,
	listProducts as listProductsQuery
} from "../graphql/queries";
import {
	createContributor as createContributorMutation,
	createPost as createPostMutation,
	createImage as createImageMutation,
	createSrc as createSrcMutation,
	createCatLink as createCatLinkMutation,
	createProductLink as createProductLinkMutation
} from "../graphql/mutations";
import {
	Modal,
	Row,
	Col,
	Form,
	Button,
	InputGroup,
	Image
} from "react-bootstrap";

import Select from "react-select";
import makeAnimated from "react-select/animated";
import PlayerComponent from "../components/player";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import "./forms.css";

Storage.configure({ level: "private" });

function SubmitPostModal(props) {
	return (
		<Modal {...props} size="lg" aria-labelledby="contained-modal-title">
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title">
					SUBMIT A QUICK NOTES POST FROM THE FIELD
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<p>
					Share your success by adding your contribution from the field. These
					posts can be used to help all of us learn about product applications
					and for sharing with customers to show just how the products work.
				</p>
				<h4>Please make sure your post meets the following criteria:</h4>
				<p>
					Please be sure that the product is being used for appropriate
					applications as noted on our label and other literature.
				</p>
				Each post should include:
				<ul>
					<li>the product(s) featured</li>
					<li>
						the application needed by your customer (i.e. problem being solved){" "}
					</li>
					<li>images and/or a video</li>
				</ul>
				<p>
					We will review your submission and United reserves the right to post
					your or not post your submission.
				</p>
			</Modal.Body>
			<Modal.Footer>
				<Row>
					<Col xs={12}>
						<Button block size={"lg"} onClick={props.onSubmit}>
							Okay, Let&apos;s Go --&gt;
						</Button>
					</Col>
					<Col>
						<p>
							If you prefer to email instead of using this form, you can send
							your QUICK NOTE to us at &nbsp;
							<a href="mailto:fromthefield@unitedlabsinc.com">
								fromthefield@unitedlabsinc.com
							</a>
						</p>
					</Col>
				</Row>
			</Modal.Footer>
		</Modal>
	);
}

class CreatePostComponent extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			moadlVisible: false,
			contributors: { items: [{ id: " ", name: " " }] },
			products: [{ id: "", name: "" }],
			productOptions: [{ label: "", value: "" }],
			selectedCats: [],
			categories: [{ id: "", name: "" }],
			disableIdGenerator: false,
			createPostInput: {
				id: this.createUID(6),
				type: "fromthefield",
				title: "",
				shortDesc: "",
				keywordstring: "  ",
				postContributorId: "",
				startPublishing: 0,
				endPublishing: 0
			},
			images: [],
			createVideoInput: "",
			isFieldInValid: {}
		};
	}
	//componentDidMount() { this.setState({}})}
	createUID = length => {
		let result = "";
		const characters =
			"ABCDEFGHIJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz0123456789-+";
		const charactersLength = characters.length;
		for (var i = 0; i < length; i++) {
			result += characters.charAt(Math.floor(Math.random() * charactersLength));
		}
		return result;
	};
	resetUID = () => {
		this.setState({
			createPostInput: { ...this.state.createPostInput, id: this.createUID(6) }
		});
	};

	showFormModal = e => {
		if (!this.state.formModalVisible) {
			e;
			this.getCategories();
			this.getProducts();
			this.setContributorToUser();
		}
		this.setState({
			formModalVisible: !this.state.formModalVisible,
			addPostModalVisible: false
		});
	};

	setContributorToUser = async () => {
		await this.getContributors();
		let id = "";
		await Auth.currentAuthenticatedUser({
			bypassCache: false // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
		})
			.then(user => {
				id = user.username.substring(0, user.username.lastIndexOf("@"));
				if (!this.state.contributors.items.some(e => e.id === id)) {
					this.addUserToContributors(id);
					this.getContributors();
				}
			})
			.catch(err => console.log(err));
		this.setState({
			createPostInput: { ...this.state.createPostInput, postContributorId: id }
		});
	};

	getContributors = async () => {
		try {
			const getcontributorsresponse = await API.graphql(
				graphqlOperation(listContributorsQuery)
			);
			this.setState({
				contributors: getcontributorsresponse.data.listContributors
			});
		} catch (err) {
			console.log("error fetching contributors");
		}
	};
	sortCompare = (a, b) => {
		// Use toUpperCase() to ignore character casing
		const stringA = a.id.toUpperCase().padStart(4, "0");
		const stringB = b.id.toUpperCase().padStart(4, "0");
		let comparison = 0;
		if (stringA > stringB) {
			comparison = 1;
		} else if (stringA < stringB) {
			comparison = -1;
		}
		return comparison;
	};

	getCategories = async () => {
		try {
			const getcategoriesresponse = await API.graphql(
				graphqlOperation(listCategoriesQuery, { limit: 100 })
			);
			this.setState({
				categories: getcategoriesresponse.data.listCategorys.items.sort(
					this.sortCompare
				)
			});
		} catch (err) {
			console.log("error fetching categories");
		}
	};

	getProducts = async () => {
		try {
			const getproductsresponse = await API.graphql(
				graphqlOperation(listProductsQuery, { limit: 1000 })
			);
			let sortedProducts = getproductsresponse.data.listProducts.items.sort(
				this.sortCompare
			);
			const productOptions = sortedProducts.map(item => {
				return {
					...item,
					label: " " + item.id + " " + item.name + " " + item.description,
					value: item.id
				};
			});
			this.setState({ products: productOptions });
		} catch (err) {
			console.log("error fetching products");
		}
	};
	clearSelectedCats = () => {
		this.setState({ selectedCats: [] });
	};
	onPostInputChange = e => {
		let value =
			e.target.type === "checkbox" ? e.target.checked : e.target.value;
		this.setState({
			createPostInput: { ...this.state.createPostInput, [e.target.name]: value }
		});
	};

	onImageFileChange(e, imageid) {
		let images = this.state.images;
		images.find(x => x.createImageInput.id === imageid).sources = [
			{
				createSrcInput: {
					id: this.createUID(8),
					display: "carousel",
					srcImageId: imageid,
					type: e.target.files[0].type
				},
				blob: URL.createObjectURL(e.target.files[0]),
				file: e.target.files[0]
			}
		];
		this.setState({ images: images });
	}

	onImageInputChange = (e, imageid) => {
		let value =
			e.target.type === "checkbox" ? e.target.checked : e.target.value;
		let images = this.state.images;
		images.find(x => x.createImageInput.id === imageid).createImageInput[
			e.target.name
		] = value;
		this.setState({
			images: images
		});
	};

	onVideoInputChange = e => {
		let value =
			e.target.type === "checkbox" ? e.target.checked : e.target.value;
		this.setState({
			createVideoInput: {
				...this.state.createVideoInput,
				[e.target.name]: value
			}
		});
	};
	onVideoFileChange = e => {
		this.setState({
			createVideoInput: {
				...this.state.createVideoInput,
				createSrcInput: {
					id: this.createUID(8),
					display: "video",
					srcVideoId: this.state.createVideoInput.id,
					type: e.target.files[0].type,
					file: e.target.files[0]
				},
				blob: URL.createObjectURL(e.target.files[0])
			}
		});
	};
	onCategoriesChange = e => {
		let options = e.target.options;
		let values = [];
		for (var o of options) {
			if (o.selected) values.push({ id: o.value, name: o.textContent });
		}
		this.setState({
			selectedCats: values
		});
	};

	delimg = imageid => {
		console.log("deleting image " + imageid);
		let images = this.state.images;
		let index = images.findIndex(x => x.createImageInput.id === imageid);
		if (this.state.images.length > 1) {
			images.splice(index, 1);
			this.setState({ images: images });
		} else {
			let images = [];
			this.setState({ images: images });
		}
	};

	validateField = (e, options) => {
		let invalid = false;
		let checkValue = options.checkValue ? options.checkValue : "";
		console.log("checking " + options.controlId + " with value: " + checkValue);
		console.log(checkValue.length);
		if (
			(options.allowEmpty === false && checkValue.length < 1) ||
			(options.minLength && checkValue.length < options.minLength) ||
			(options.maxLength && checkValue.length > options.maxLength)
		) {
			invalid = true;
		}
		this.setState({
			isFieldInValid: {
				...this.state.isFieldInValid,
				[options.controlId]: invalid
			}
		});
	};
	handleAddImageBtn = async () => {
		let images = this.state.images ? this.state.images : [];
		images.push({
			key: images.length,
			createImageInput: {
				id: this.createUID(8),
				title: " ",
				caption: " ",
				imageInPostId: this.state.createPostInput.id
			}
		});
		await this.setState({ images: images });
		this.setState({ disableIdGenerator: true });
	};
	handleAddVideoBtn = () => {
		let video = this.state.createVideoInput
			? this.state.createVideoInput
			: { id: this.createUID(8) };
		this.setState({ createVideoInput: video });
	};

	addUserToContributors = async id => {
		let createContributorVars = {
			input: {
				id: id,
				name: id,
				level: 0
			}
		};
		try {
			await API.graphql(
				graphqlOperation(createContributorMutation, createContributorVars)
			);
		} catch (ex) {
			throw new Error(
				"createContributorMutation Failed \n" + ex.errors[0].message
			);
		}
	};

	handleNewPostForm = async () => {
		let createPostVars = {
			input: this.state.createPostInput
		};
		try {
			let newpost = {};
			try {
				newpost = await API.graphql(
					graphqlOperation(createPostMutation, createPostVars)
				);
			} catch (ex) {
				throw new Error("createPostMutation Failed \n" + ex.errors[0].message);
			}
			this.state.selectedCats.map(async cat => {
				let createCatLinkVars = {
					input: {
						catLinkPostId: newpost.data.createPost.id,
						catLinkCategoryId: cat.value
					}
				};
				try {
					await API.graphql(
						graphqlOperation(createCatLinkMutation, createCatLinkVars)
					);
				} catch (ex) {
					throw new Error(
						"createCatLinkMutation Failed \n" + ex.errors[0].message
					);
				}
			});
			this.state.selectedProducts.map(async prod => {
				let createProductLinkVars = {
					input: {
						productLinkPostId: newpost.data.createPost.id,
						productLinkProductId: prod.id
					}
				};
				try {
					await API.graphql(
						graphqlOperation(createProductLinkMutation, createProductLinkVars)
					);
				} catch (ex) {
					throw new Error(
						"createProductLinkMutation Failed \n" + ex.errors[0].message
					);
				}
			});

			if (this.state.images.length > 0) {
				let imagesToCreate = this.state.images;
				imagesToCreate.map(async image => {
					let createImageVars = { input: image.createImageInput };
					await API.graphql(
						graphqlOperation(createImageMutation, createImageVars)
					);
					image.sources.forEach(src => {
						let file = src.file;
						let path =
							this.state.createPostInput.id +
							"/" +
							image.createImageInput.id +
							"_" +
							file.name;
						let options = { level: "protectecd", contentType: src.type };
						let createSrcVars = { input: src.createSrcInput };

						try {
							Storage.put(path, file, options).then(object => {
								if (!object) {
									throw new Error(
										"Uploading File Failed: " + file.name + " \n"
									);
								}
								createSrcVars.input.s3object = {
									bucket: Storage._config.AWSS3.bucket,
									region: Storage._config.AWSS3.region,
									key: object.key
								};
								API.graphql(graphqlOperation(createSrcMutation, createSrcVars));
							});
						} catch (ex) {
							throw new Error("Create Source Failed \n" + ex.errors[0].message);
						}
					});
				});
			}
			if (this.state.createVideoInput.createSrcInput) {
				const createSrcVars = {
					input: {
						id: this.state.createVideoInput.createSrcInput.id,
						display: this.state.createVideoInput.createSrcInput.display,
						srcVideoId: this.state.createVideoInput.createSrcInput.srcVideoId,
						type: this.state.createVideoInput.createSrcInput.type
					}
				};
				const vidfile = this.state.createVideoInput.createSrcInput.file;
				const path =
					this.state.createPostInput.id + "/" + createSrcVars.input.id;

				/*
				const createVideoVars = {
					input: {
						id: this.state.createVideoInput.id,
						title: this.state.createVideoInput.title,
						iscustomer: false,
						istraining: false,
						url: " ",
						videoInPostId: newpost.data.createPost.id
					}
				};
				 try {
					const newVid = await API.graphql(
						graphqlOperation(createVideoMutation, createVideoVars)
					);
				} catch (ex) {
					throw new Error(
						"createVideoMutation Failed \n" + ex.errors[0].message
					);
				} */
				let options = { level: "protectecd", contentType: createSrcVars.type };
				try {
					Storage.put(path, vidfile, options).then(object => {
						if (!object) {
							throw new Error("Uploading File Failed: " + vidfile.name + " \n");
						}
						createSrcVars.input.s3object = {
							bucket: Storage._config.AWSS3.bucket,
							region: Storage._config.AWSS3.region,
							key: object.key
						};
						API.graphql(graphqlOperation(createSrcMutation, createSrcVars));
					});
				} catch (ex) {
					throw new Error("Create Source Failed \n" + ex.errors[0].message);
				}
			}

			this.setState({
				modalVisible: false,
				selectedCats: [],
				selectedProducts: [],
				disableIdGenerator: false,
				createPostInput: {
					id: this.createUID(6),
					type: "fromthefield",
					title: "",
					shortDesc: "",
					longDesc: "",
					keywordstring: " ",
					postContributorId: "",
					startPublishing: 0,
					endPublishing: 0
				},
				images: [],
				createVideoInput: ""
			});
			this.setState({ formModalVisible: false });
			this.setState({ thanksModalVisible: true });
			return newpost;
		} catch (err) {
			console.log("error creating post. " + err);
		}
	};

	render() {
		let adminView =
			this.props.userscope === "admin" && this.props.section === "admin";
		let adminElementStyle = { display: "none" };
		if (adminView) {
			adminElementStyle = {};
		}
		let addPostModalHide = () => this.setState({ addPostModalVisible: false });
		let addPostModalShow = () => this.setState({ addPostModalVisible: true });
		let formModalHide = () => {
			alertShow();
		};
		let alertShow = () => this.setState({ alertModalVisible: true });
		let alertHide = () => this.setState({ alertModalVisible: false });
		let cancelForm = () => {
			alertHide();
			this.setState({ formModalVisible: false });
		};
		let categoryOptions = this.state.categories.map(item => {
			return {
				label: item.name,
				value: item.id
			};
		});
		let thanksHide = () => this.setState({ thanksModalVisible: false });

		return (
			<div className="CreatePostComponent">
				Have a contribution?
				<Button
					id="submit_post_button"
					variant="success"
					onClick={addPostModalShow}
				>
					SUBMIT A POST
				</Button>
				<SubmitPostModal
					show={this.state.addPostModalVisible}
					onHide={addPostModalHide}
					onSubmit={this.showFormModal}
				/>
				<Modal
					show={this.state.formModalVisible}
					onHide={formModalHide}
					size="xl"
				>
					<Modal.Header closeButton>
						<Modal.Title>SUBMIT A QUICK NOTES POST FROM THE FIELD</Modal.Title>
					</Modal.Header>

					<Modal.Body id="collapse-content">
						<br />
						<Form id="newPostForm">
							<Form.Row style={adminElementStyle}>
								<Form.Group as={Col} controlId="newPostForm.newid">
									<Form.Label>Unique identifier:</Form.Label>
									<InputGroup style={{ marginBottom: "-25px" }}>
										<Form.Control
											type="text"
											value={this.state.createPostInput.id}
											onChange={this.onPostInputChange}
											name="newid"
											disabled
										/>
										<InputGroup.Append>
											<Button
												variant="outline-secondary"
												onClick={this.resetUID}
												disabled={this.state.disableIdGenerator}
											>
												Generate New ID
											</Button>
										</InputGroup.Append>
									</InputGroup>
									<Form.Text className="text-muted">
										This will be used as the url suffix
									</Form.Text>
								</Form.Group>
								<Form.Group as={Col} controlId="newPostForm.contributor">
									<Form.Label> Contributor:</Form.Label>
									<Form.Control
										as="select"
										name="postContributorId"
										disabled={!adminView}
										value={this.state.createPostInput.postContributorId}
										onChange={this.onPostInputChange}
										className={
											!this.state.createPostInput.postContributorId &&
											"is-invalid"
										}
									>
										<option> </option>
										{this.state.contributors.items.map(contributor => (
											<option key={contributor.id} value={contributor.id}>
												{contributor.name}
											</option>
										))}
									</Form.Control>
								</Form.Group>
								<Form.Group as={Col} controlId="newPostForm.type">
									<Form.Label>Type:</Form.Label>
									<Form.Control
										type="text"
										value={this.state.createPostInput.type}
										name="type"
										disabled
									/>
								</Form.Group>
							</Form.Row>
							<Form.Row>
								<Form.Group as={Col} md={4} controlId="newPostForm.title">
									<Form.Label>Title:</Form.Label>
									<Form.Control
										type="text"
										value={this.state.createPostInput.title}
										onChange={this.onPostInputChange}
										onBlur={e =>
											this.validateField(e, {
												allowEmpty: false,
												maxLength: 42,
												controlId: "newPostForm.title",
												checkValue: e.target.value
											})
										}
										className={
											this.state.isFieldInValid["newPostForm.title"] &&
											"is-invalid"
										}
										name="title"
										placeholder="(Required)"
									/>{" "}
									<div className="invalid-feedback">
										&nbsp; A title less than 42 characters long is required.
									</div>
								</Form.Group>
								<Form.Group as={Col} md={8} controlId="newPostForm.shortDesc">
									<Form.Label>Short Description:</Form.Label>
									<Form.Control
										type="text"
										value={this.state.createPostInput.shortDesc}
										onChange={this.onPostInputChange}
										onBlur={e =>
											this.validateField(e, {
												allowEmpty: false,
												minLength: 10,
												maxLength: 42,
												controlId: "newPostForm.shortDesc",
												checkValue: e.target.value
											})
										}
										className={
											this.state.isFieldInValid["newPostForm.shortDesc"] &&
											"is-invalid"
										}
										name="shortDesc"
										placeholder="(Required) Write a short sentence saying what the post is..."
									/>
									<div className="invalid-feedback">
										&nbsp; Short description must be between 10 and 42
										characters long.
									</div>
								</Form.Group>
							</Form.Row>
							<Form.Row>
								<Form.Group as={Col} controlId="newPostForm.longDesc">
									<Form.Label>Long Description:</Form.Label>
									<Form.Control
										as="textarea"
										name="longDesc"
										rows="6"
										value={this.state.createPostInput.longDesc}
										onChange={this.onPostInputChange}
										onBlur={e =>
											this.validateField(e, {
												allowEmpty: false,
												minLength: 10,
												controlId: "newPostForm.longDesc",
												checkValue: e.target.value
											})
										}
										className={
											this.state.isFieldInValid["newPostForm.longDesc"] &&
											"is-invalid"
										}
										placeholder="(Required) Describe the post here..."
									/>
									<div className="invalid-feedback">
										&nbsp; Long description is too short, write something a
										little longer.
									</div>
								</Form.Group>
							</Form.Row>
							<Form.Row>
								<Form.Group as={Col} lg={8} controlId="newPostForm.products">
									<Form.Label>Products Mentioned:</Form.Label>
									<Select
										options={this.state.products}
										onChange={selectedProducts =>
											this.setState({ selectedProducts: selectedProducts })
										}
										selected={this.state.selectedProducts}
										isMulti
										components={makeAnimated()}
										onBlur={e =>
											this.validateField(e, {
												allowEmpty: false,
												controlId: "newPostForm.products",
												checkValue: this.state.selectedProducts
											})
										}
										className={
											this.state.isFieldInValid["newPostForm.products"] &&
											"is-invalid"
										}
										placeholder="(Required) Start typing to search by product number or keyword"
									/>
								</Form.Group>
							</Form.Row>
							<Form.Row>
								<Form.Group as={Col} lg={8} controlId="newPostForm.categories">
									<Form.Label>Categories:</Form.Label>
									<Select
										isMulti
										options={categoryOptions}
										onChange={selectedCats =>
											this.setState({ selectedCats: selectedCats })
										}
										components={makeAnimated()}
										onBlur={e =>
											this.validateField(e, {
												allowEmpty: false,
												controlId: "newPostForm.categories",
												checkValue: this.state.selectedCats
											})
										}
										selected={this.state.selectedCats}
										className={
											this.state.isFieldInValid["newPostForm.categories"] &&
											"is-invalid"
										}
										placeholder="(Required) Select at least one category..."
									/>
								</Form.Group>
							</Form.Row>
							<Form.Row style={adminElementStyle}>
								<Form.Group as={Col} controlId="newPostForm.keywordstring">
									<Form.Label>keywordstring:</Form.Label>
									<Form.Control
										type="text"
										value={this.state.createPostInput.keywordstring}
										onChange={this.onPostInputChange}
										name="keywordstring"
									/>
								</Form.Group>
							</Form.Row>
							{this.state.images.length > 0 &&
								this.state.images.map(image => (
									<Form.Row
										key={"row" + image.createImageInput.id}
										style={{
											borderRadius: "6px",
											backgroundColor: "rgb(246,246,246)",
											margin: "3px",
											padding: "3px"
										}}
									>
										<Form.Group
											key={"file" + image.id}
											as={Col}
											sm={3}
											controlId="newPostForm.file"
											hidden={image.sources}
										>
											<Form.Label>
												Image{" "}
												{this.state.images.findIndex(
													x =>
														x.createImageInput.id === image.createImageInput.id
												) + 1}
												:
											</Form.Label>

											<Form.Control
												type="file"
												name="images"
												accept="image/png, image/jpeg"
												key={image.id}
												onChange={e =>
													this.onImageFileChange(e, image.createImageInput.id)
												}
											/>
										</Form.Group>
										{image.sources && (
											<Col sm={3}>
												<img
													src={image.sources[0].blob}
													style={{
														maxWidth: "100%",
														padding: "5px"
													}}
													alt={"file " + this.state.images.length}
												/>
											</Col>
										)}
										<Form.Group
											key={"title" + image.createImageInput.id}
											as={Col}
											sm={2}
											onChange={e =>
												this.onImageInputChange(
													e,
													image.createImageInput.id,
													this.state.images.length
												)
											}
											controlId="newPostForm.imageTitle"
										>
											<Form.Label>
												Image Title: <sub>(optional)</sub>
											</Form.Label>
											<Form.Control type="text" name="title" />
										</Form.Group>
										<Form.Group
											key={"caption" + image.createImageInput.id}
											as={Col}
											onChange={e =>
												this.onImageInputChange(e, image.createImageInput.id)
											}
											controlId="newPostForm.imageCaption"
										>
											<Form.Row>
												<Form.Label>
													Image Caption: <sub>(optional)</sub>
												</Form.Label>
												<Form.Control type="text" name="caption" />
												<Form.Group as={Col} xs={12}>
													<br />
													<Button
														onClick={e =>
															this.delimg(image.createImageInput.id, e)
														}
														variant="danger"
													>
														Remove Image
													</Button>
												</Form.Group>
											</Form.Row>
										</Form.Group>
									</Form.Row>
								))}
							<Form.Row>
								<Button
									onClick={this.handleAddImageBtn}
									disabled={
										this.state.images.length > 0 &&
										(this.state.images[this.state.images.length - 1].sources ===
											undefined ||
											this.state.images.length > 7)
									}
								>
									Add Images
								</Button>
							</Form.Row>
							{this.state.createVideoInput && (
								<Form.Row>
									<Form.Group
										as={Col}
										sm={3}
										controlId="newPostForm.videoFile"
										hidden={this.state.createVideoInput.createSrcInput}
									>
										<Form.Label>Video:</Form.Label>
										<Form.Control
											type="file"
											name="video"
											accept="video/mp4, video/quicktime, video/x-ms-wmv, video/x-flv, video/3gpp, video/x-msvideo"
											onChange={this.onVideoFileChange}
										/>
									</Form.Group>
									{this.state.createVideoInput.createSrcInput && (
										<Col sm={5}>
											<PlayerComponent
												source={{
													src: this.state.createVideoInput.blob,
													type: this.state.createVideoInput.createSrcInput.type
												}}
												playing={false}
											/>
										</Col>
									)}
									<Form.Group
										as={Col}
										sm={6}
										onChange={this.onVideoInputChange}
										controlId="newPostForm.videoTitle"
									>
										<Form.Label>Video Title:</Form.Label>
										<Form.Control type="text" name="title" />
									</Form.Group>
									<Form.Group as={Col} xs={12}>
										<br />
										<Button
											onClick={this.setState({ createVideoInput: "" })}
											variant="danger"
										>
											Remove Video
										</Button>
									</Form.Group>
								</Form.Row>
							)}
							<Form.Row>
								<Button
									onClick={this.handleAddVideoBtn}
									hidden={this.state.createVideoInput}
								>
									Add a Video
								</Button>
							</Form.Row>
							<Form.Row>
								<Button
									variant="success"
									size="lg"
									block
									onClick={this.handleNewPostForm}
								>
									Submit My Quick Note
								</Button>
							</Form.Row>
							<br />
						</Form>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="danger" onClick={formModalHide}>
							Cancel
						</Button>
					</Modal.Footer>
				</Modal>
				<Modal
					size="lg"
					show={this.state.alertModalVisible}
					onHide={alertHide}
					aria-labelledby="error-modal"
					className="error-modal"
					style={{ paddingTop: "10px" }}
				>
					<Modal.Body>
						<p className="error-message">
							<FontAwesomeIcon icon={faExclamationCircle} />
							&nbsp; &nbsp; Are you sure you want to cancel?
						</p>
						<p>
							<Button
								variant="link"
								onClick={alertHide}
								style={{
									color: "black",
									fontWeight: "bold",
									textDecoration: "underline"
								}}
							>
								No, Stay
							</Button>
							&nbsp; &nbsp;
							<Button variant="light" onClick={cancelForm}>
								Yes, Cancel
							</Button>
						</p>
					</Modal.Body>
				</Modal>
				<Modal
					size="md"
					show={this.state.thanksModalVisible}
					onHide={thanksHide}
				>
					<Modal.Body>
						<p style={{ textAlign: "center" }}>
							<Image
								style={{ maxWidth: "400px", borderRadius: "6px" }}
								src="https://s3.amazonaws.com/ulabs.info/media/NewPost_Submitted_thanks.jpg"
							/>
						</p>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="light" onClick={thanksHide}>
							Close
						</Button>
					</Modal.Footer>
				</Modal>
			</div>
		);
	}
}

export default CreatePostComponent;
